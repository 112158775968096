.contact-links {
    display: flex;
    justify-content: center;
    gap: 30px; /* Space between buttons */
  }
  
  .contact-link {
    display: inline-block;
    height: 20px;
    width: 15vw;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    text-indent: -9999px; /* Hides the link text */
    overflow: hidden;
  }
  
  .linkedin {
    background-image: url("res/svg/linkedin.svg");
  }
  
  .github {
    background-image: url("res/svg/github.svg");
  }
  
  .spotify {
    background-image: url("res/svg/spotify_full.svg");
  }