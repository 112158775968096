:root {
    --primary-color: #221100;
    --secondary-color: #442200;
    --tertiary-color: #884400;
    --quaternary-color: #FF8800;
    --quinary-color: #FFBB88;
    --senary-color: #FFEECC;
    --septary-color: #FFF7E6;
    --octonary-color: #FFFBF2;
    scrollbar-width: thin;
    scrollbar-color: #221100 #FFEECC;
}

body {
    margin: 0;
}

.ant-tag {
    margin-top: 8px;
}